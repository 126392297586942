import { CalendarToday, Person } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Card, CircularProgress, Divider, Grid, IconButton, Modal, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { ReactComponent as GovLogo } from "../../../../assets/gov-logo.svg";
import imgLogoACT from "../../../../assets/logo_ACT.png";
import imgLogoNSW from "../../../../assets/logo_NSW.png";
import imgLogoNT from "../../../../assets/logo_NT.png";
import imgLogoQLD from "../../../../assets/logo_QLD.png";
import imgLogoSA from "../../../../assets/logo_SA.png";
import imgLogoTAS from "../../../../assets/logo_TAS.png";
import imgLogoVIC from "../../../../assets/logo_VIC.png";
import imgLogoWA from "../../../../assets/logo_WA.png";
import { get } from "../../../../utils/api";
import { AustralianStateEnum } from "../../../../utils/constants";
import theme from "../../../../utils/theme";

import FilePreview from '../../../reusable/FilePreview';
import { Space } from '../../../reusable/Scaffolds';

const EducatorDocumentModal = ({ open, onClose, educatorId, orgId }) => {

  const baseUrl = "/file-content?filePath=";
  const [member, setMember] = useState({});
  const [loading, setLoading] = useState(true);

  const loadMember = useCallback(async () => {
    if (!educatorId) {
      return;
    }
    try {
      const response = await get(`/onboarding?educator_id=${educatorId}&org_id=${orgId}`);
      setMember(response);
    } catch (error) {
      console.error("Failed to fetch members:", error);
    } finally {
      setLoading(false);
    }
  }, [educatorId, orgId]);

  useEffect(() => {
    loadMember();
  }, [educatorId, orgId, loadMember]);

  let documents = [
    { name: "Qualification", url: member.qualification_url },
    { name: "Previous Qualification", url: member.previous_qualification_url },
    { name: "Resume", url: member.resume_url },
    { name: "CPR Certificate", url: member?.cpr_cert_url },
    { name: "First Aid Certificate", url: member?.first_aid_cert },
    { name: "Child Protectiom Certificate", url: member?.child_protection_cert },
    { name: "Transcript", url: member.transcript_url },
    { name: "Food handling certificate", url: member.food_handling_cert_url },
  ].filter(doc => doc.url);

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ ...modalStyle }}>
        <IconButton onClick={onClose} sx={{ position: 'absolute', top: 8, right: 8 }}>
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" component="h2">
          Educator Documents
        </Typography>

        <Box sx={{ display: loading ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
        </Box>

        {loading ? null : (
          <>
            {member?.wwcc_data?.length && <Grid container spacing={2} sx={{ mt: 2, overflow: 'auto', maxHeight: '80%' }}>
              {
                member?.wwcc_data.map(wwccItem => {
                  return (
                    <Grid item xs={6} key={wwccItem.state}>
                      <WwccCard wwccItem={wwccItem} member={member} />
                    </Grid>
                  )
                })
              }
            </Grid>
            }
            <Grid container spacing={2} sx={{ mt: 2, overflow: 'auto', maxHeight: '80%', display: loading ? 'none' : 'flex' }}>
              {documents.length > 0 ? (
                documents.map((doc, index) => (
                  <FilePreview
                    key={index}
                    fileUrl={`${baseUrl}${doc.url}`}
                    title={doc.name}
                    verifyCallback={() => console.log('Verify callback')}
                    isShowDocumentButton={false}
                  />
                ))
              ) : (
                <Typography variant="body1" sx={{ textAlign: 'center', width: '100%' }}>
                  No documents available.
                </Typography>
              )}
            </Grid>
          </>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button variant="contained" color="primary" onClick={onClose}>
            Close
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const WwccCard = ({ wwccItem, member }) => {
  const getIcon = () => {
    switch (wwccItem?.state) {
      case AustralianStateEnum.NSW:
        return <img src={imgLogoNSW} alt="Wwcc type" style={{ height: '64px' }} />
      case AustralianStateEnum.VIC:
        return <img src={imgLogoVIC} alt="Wwcc type" style={{ height: '64px' }} />
      case AustralianStateEnum.QLD:
        return <img src={imgLogoQLD} alt="Wwcc type" style={{ height: '64px' }} />
      case AustralianStateEnum.ACT:
        return <img src={imgLogoACT} alt="Wwcc type" style={{ height: '64px' }} />
      case AustralianStateEnum.SA:
        return <img src={imgLogoSA} alt="Wwcc type" style={{ height: '64px' }} />
      case AustralianStateEnum.WA:
        return <img src={imgLogoWA} alt="Wwcc type" style={{ height: '64px' }} />
      case AustralianStateEnum.TAS:
        return <img src={imgLogoTAS} alt="Wwcc type" style={{ height: '64px' }} />
      case AustralianStateEnum.NT:
        return <img src={imgLogoNT} alt="Wwcc type" style={{ height: '64px' }} />
      default:
        return <></>
    }
  }
  return (
    <Card
      sx={{
        borderRadius: "10px",
        backgroundColor: theme.palette.surface.main,
        border: '1px solid #F1F1F1'
      }}
    >
      <Box sx={{ paddingX: 1, paddingY: 1 }}>
        <Space>
          <GovLogo />
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 400,
              marginLeft: "4px",
              marginRight: "auto",
            }}
          >
            Working with Children
          </Typography>
        </Space>
      </Box>
      <Divider />
      <Box sx={{ paddingX: 1, paddingY: 1 }}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={8}>
            <Space gap={2}>
              <Space gap={'4px'} style={tagStyle}>
                <Person fontSize="small" style={{ color: theme.palette.common.white }} />
                <Typography sx={tagTitleStyle}>
                  {member?.first_name}
                </Typography>
              </Space>
              <Space gap={'4px'} style={{ ...tagStyle, background: '#37acff' }}>
                <CalendarToday fontSize="small" style={{ color: theme.palette.text }} />
                <Typography sx={{ ...tagTitleStyle, color: theme.palette.text, fontWeight: 400 }}>
                  {wwccItem?.wwcc_number}
                </Typography>
              </Space>
            </Space>
          </Grid>
          <Grid item xs={4}>
            <Space style={{ justifyContent: 'flex-end' }}>
              {getIcon()}
            </Space>
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
}

const tagStyle = {
  background: '#4e4e4e',
  borderRadius: 4,
  padding: 4,
}

const tagTitleStyle = {
  fontSize: 14,
  fontWeight: 'bold',
  marginLeft: "4px",
  marginRight: "auto",
  color: theme.palette.common.white
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  maxHeight: '90%',
  overflow: 'auto',
};

export default EducatorDocumentModal;