import React from 'react'
import { Chip } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { M3TitleSmall } from '../../../../reusable/TextStyles';

const AccountStatus = ({ label, status }) => {
  const colors = {
    Complete: '#5a60d5',
    Verified: '#6fbd65',
    Verifying: '#ed6c02',
    Issue: 'red',
  };

  return (
    <>
      <M3TitleSmall variant="body1" component="div" sx={{ width: '150px', wordBreak: 'break-all' }}>
        {label}
      </M3TitleSmall>
      <Chip
        label={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            {status}
            <CheckCircle style={{ fontSize: '15px', marginLeft: '2px', color: '#6c5b5b' }} />
          </span>
        }
        size="small" 
        sx={{backgroundColor: colors[status], color: 'white'}}
      />
    </>
  )
}

export default AccountStatus